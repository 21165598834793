const moment = require('moment')
const axios = require('axios')

export const state = () => ({
  sandwichID: 425789,
  client: 'web',
  appState: {},
  deviceId: null,
  loading: false,
  errors: [],
  location: null,
  activeLocation: null,
  locationList: null,
  allLocations: [],
  allExpandedLocations: [],
  jobLocations: [],
  defaultMenu: null,
  menu: null,
  order: null,
  handoffToggle: 'pickup',
  handoff: {
    first_name: null,
    last_name: null,
    email: null,
    address: null,
    city: null,
    zip: null,
    phone: null,
    Make: null,
    Model: null,
    Color: null
  },
  handoffErrors: {
    address: false,
    city: false,
    zip: false,
    phone: false
  },
  payments: [],
  submitting: false,
  showCart: false,
  showMobileMenu: false,
  showBox: true,
  user: null,
  favelocations: [],
  token: null,
  redirect: null,
  global: [],
  offers: [],
  walletOffers: {},
  topBanner: {},
  orderConfirming: false,
  miniLoading: false,
  headerHeight: null,
  footerHeight: null,
  createAccount: true,
  guestUser: null,
  homepageCarouselContentCards: [],
  homepageTopAlertContentCards: [],
  offerScreenContentCards: [],
  offerToApply: null,
  activeOrder: null
})

export const mutations = {
  setClient(state, str) {
    state.client = str
  },

  setAppState(state, obj) {
    if(obj === null) {
      state.appState = {}
      localStorage.removeItem('boj_appState')
    } else {
      state.appState = obj
      localStorage.setItem('boj_appState', JSON.stringify(obj))
    }
  },

  setDeviceId(state, str) {
    state.deviceId = str
  },

  setLoading(state, bool) {
    state.loading = bool
  },
  setMiniLoading(state, bool) {
    state.miniLoading = bool
  },

  setTopBanner(state, obj) {
    state.topBanner = obj
  },

  setErrors(state, arr) {
    state.errors = arr
  },

  setLocation(state, obj) {
    if (obj == null) {
      localStorage.removeItem('boj_loc')
      this.$cookiz.remove('boj_loc_id')
    } else {
      obj.expires = moment()
        .add(1, 'day')
        .format('LLLL')
      localStorage.setItem('boj_loc', JSON.stringify(obj))
      this.$cookiz.set('boj_loc_id', JSON.stringify(obj.id))
    }
    state.location = obj
  },

  setActiveLocation(state, obj) {
    state.activeLocation = obj
  },

  setLocationList(state, something) {
    state.locationList = something
  },

  setAllLocations(state, arr) {
    state.allLocations = arr
  },
  setGlobalExpandedLocations(state, obj) {
    if (obj == null) {
      localStorage.removeItem('boj_exp_markets')
    } else {
      obj.expires = moment()
        .add(1, 'day')
        .format('LLLL')
      localStorage.setItem('boj_exp_markets', JSON.stringify(obj))
    }

    state.allExpandedLocations = obj
  },
  setExpandedLocations(state, arr) {
    /*  if (arr == null) {
      if (localStorage !== 'undefined')
        localStorage.removeItem('boj_exp_markets')
    } else {
      arr.expires = moment()
        .add(1, 'day')
        .format('LLLL')
      if (localStorage !== 'undefined')
        localStorage.setItem('boj_exp_markets', JSON.stringify(arr))
    } */

    state.allExpandedLocations = arr
  },

  setJobLocations(state, arr) {
    state.jobLocations = arr
  },

  setDefaultMenu(state, obj) {
    state.defaultMenu = obj
  },

  setMenu(state, obj) {
    if (obj == null) {
      localStorage.removeItem('boj_menu')
    } else {
      obj.expires = moment()
        .add(1, 'hour')
        .format('LLLL')
      localStorage.setItem('boj_menu', JSON.stringify(obj))
    }
    state.menu = obj
  },

  setOrder(state, obj) {
    if (obj == null) {
      localStorage.removeItem('boj_order')
    } else {
      obj.expires = moment()
        .add(1, 'days')
        .format('LLLL')
      localStorage.setItem('boj_order', JSON.stringify(obj))
    }
    state.order = obj
  },

  setOffers(state, obj) {
    state.offers = obj
  },

  setWalletOffers(state, obj) {
    localStorage.setItem('boj_offers', JSON.stringify(obj))
    state.walletOffers = obj
  },

  setPayments(state, arr) {
    state.payments = arr
  },

  setHandoffToggle(state, str) {
    state.handoffToggle = str
  },

  setHandoff(state, obj) {
    state.handoff = obj
  },

  setSubmitting(state, bool) {
    state.submitting = bool
  },

  setShowBox(state, bool) {
    state.showBox = bool
  },

  setShowCart(state, bool) {
    state.showCart = bool
  },

  setShowMobileMenu(state, bool) {
    state.showMobileMenu = bool
  },

  setUser(state, obj) {
    if (obj == null) {
      localStorage.removeItem('boj_user')
      localStorage.removeItem('boj_offers')
    } else {
      localStorage.setItem('boj_user', JSON.stringify(obj))
    }
    state.user = obj
  },

  setToken(state, obj) {
    if (obj == null) {
      localStorage.removeItem('boj_tok')
    } else {
      localStorage.setItem('boj_tok', JSON.stringify(obj))
    }
    state.token = obj
  },

  setFavelocations(state, arr) {
    state.favelocations = arr
  },

  setRedirect(state, str) {
    state.redirect = str
  },

  setGlobalSettings(state, data) {
    state.global = data
  },

  setOrderConfirm(state, bool) {
    state.orderConfirming = bool
  },

  setHeaderHeight(state, str) {
    state.headerHeight = str
  },

  setFooterHeight(state, str) {
    state.footerHeight = str
  },

  setCreateAccount(state, bool) {
    state.createAccount = bool
  },

  setGuestUser(state, user){
    state.guestUser = user
  },

  setHomepageCarouselContentCards(state, cards){
    state.homepageCarouselContentCards = cards
  },

  setHomepageTopAlertContentCards(state, cards){
    state.homepageTopAlertContentCards = cards
  },

  setOfferScreenContentCards(state, cards){
    state.offerScreenContentCards = cards
  },

  setOfferToApply(state, obj){
    state.offerToApply = obj
  },

  setActiveOrder(state, obj){
    state.activeOrder = obj
  },

  logContentCardImpressions(state, cards){
    this.$braze.logContentCardImpressions(cards)
  },

  logContentCardClick(state, card) {
    this.$braze.logContentCardClick(card)
  },

  logContentCardDismissal(state, card) {
    this.$braze.logCardDismissal(card)
  }
}

export const actions = {
  async nuxtServerInit({ commit }, context) {
    setGlobalSettings({ context, commit })
    const response = await axios({
      method: 'get',
      url: 'https://bojangles-prd.netlify.app/api/v1/members/offers/all',
      headers: {
        'client-type': 'web',
        'user-agent': 'web',
        env: process.env.GW_ENV || 'prod',
        version: 2
      }
    }).catch((err) => {
      return {
        status: 400
      }
    })
    if (response.status == 200) {
      commit('setOffers', response.data.offers)
    }
    const AirtablePlus = require('airtable-plus')
    const airtable = new AirtablePlus({
      baseID: process.env.AIRTABLE_APP_ID,
      apiKey: process.env.AIRTABLE_API_KEY,
      tableName: 'Careers'
    })
    const locations = await airtable.read()
    const jobLocations = []
    locations.map((l) => {
      jobLocations.push({
        key: l.fields['Franchise ID'],
        value: l.fields['Street Address'],
        city: l.fields.City,
        state: l.fields.State,
        lat: l.fields.Latitude,
        lng: l.fields.Longitude
      })
    })
    commit('setJobLocations', jobLocations)
  },
  nuxtClientInit({ commit }, context) {
    if (localStorage) {
      if (localStorage.getItem('boj_appState')) {
        commit('setAppState', JSON.parse(localStorage.getItem('boj_appState')))
      }
      if (localStorage.getItem('boj_loc')) {
        commit('setLocation', JSON.parse(localStorage.getItem('boj_loc')))
      }
      if (localStorage.getItem('boj_menu')) {
        commit('setMenu', JSON.parse(localStorage.getItem('boj_menu')))
      }
      if (localStorage.getItem('boj_order')) {
        const order = JSON.parse(localStorage.getItem('boj_order'))

        if (order.created) {
          const created = moment(order.created, "YYYYMMDD HH:mm")
          const now = moment()
          const diff = now.diff(created, 'hours')

          if (diff > 48) {
            commit('setOrder', null)
          } else {
            commit('setOrder', order)
          }
        } else {
          commit('setOrder', null)
        }
      }
      if (localStorage.getItem('boj_user')) {
        commit('setUser', JSON.parse(localStorage.getItem('boj_user')))
      }
      if (localStorage.getItem('boj_offers')) {
        commit(
          'setWalletOffers',
          JSON.parse(localStorage.getItem('boj_offers'))
        )
      }
      if (localStorage.getItem('boj_tok')) {
        commit('setToken', JSON.parse(localStorage.getItem('boj_tok')))
      }
      if (localStorage.getItem('boj_exp_markets')) {
        commit(
          'setGlobalExpandedLocations',
          JSON.parse(localStorage.getItem('boj_exp_markets'))
        )
      }
    }
    if (context.store.state.global.length < 1) {
      setGlobalSettings({ context, commit })
    }
  },

  async removeProduct({ commit }, id) {
    try {
      await Promise.all([
        this.$api.removeFromOrder(id),
        this.$api.validateOrder()
      ])
      // TODO: handle removal of all items from order, redirect or something
    } catch (error) {
      console.error(error)
    }
  },
  accountLogout({ commit }, p) {
    commit('setUser', null)
    commit('setToken', null)
    this.$api.abandonOrder()
  },
  async decreaseQuantity({ dispatch }, p) {
    if (p.quantity !== 1) {
      try {
        const prod = {
          id: p.id,
          quantity: p.quantity - 1,
          choices:
            p.choices && p.choices.length
              ? p.choices.map((c) => {
                  return {
                    id: c.option_id,
                    quantity: c.quantity
                  }
                })
              : []
        }
        await Promise.all([
          this.$api.updateInOrder([prod]),
          this.$api.validateOrder()
        ])
      } catch (error) {
        console.error(error)
      }
    }
  },
  async increaseQuantity({ dispatch, state }, { item, route }) {
    try {
      const prod = {
        id: item.id,
        quantity: item.quantity + 1,
        choices:
          item.choices && item.choices.length
            ? item.choices.map((c) => {
                return {
                  id: c.option_id,
                  quantity: c.quantity
                }
              })
            : []
      }
      await Promise.all([
        this.$api.updateInOrder([prod]),
        this.$api.validateOrder()
      ])

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'add_to_cart',
        'items': [item],
        'currency': 'USD',
        'value': item?.cost?.total || 0
      });

      if (route.name === 'order-checkout') {
        window.dataLayer.push({
          'event': 'begin_checkout',
          'items': state.order.products,
          'value': state.order.totals.total,
          'currency': 'USD'
        });
      }

    } catch (error) {
      console.error(error)
    }
  }
}

const setGlobalSettings = async ({ context, commit }) => {
  const globals = await Promise.all([
    context.$storyblok.getStories('global', 1, 100, 'global/jobs/*'),
    context.$storyblok.getStories('global/expansion-stores', 1, 100)
  ])

  let expandedLocs = []
  if (globals[1] && Array.isArray(globals[1])) {
    expandedLocs = globals[1].map((l) => {
        return { name: l.name, slug: l.slug }
    })
  }

  commit('setExpandedLocations', expandedLocs)
  commit('setGlobalSettings', globals[0])

  if (globals[0] && Array.isArray(globals[0])) {
    globals[0].map((g) => {
      if (g.slug === 'locations') {
        const locations = []
        g.content.locations.map((l) => {
          locations.push({
            key: l.id,
            value: l.address,
            city: l.city,
            state: l.state,
            lat: l.lat,
            lng: l.lng
          })
        })
        commit('setAllLocations', locations)
      } else if (g.slug === 'products') {
        commit('setDefaultMenu', g.content)
      } else if (g.slug === 'top-banner') {
        commit('setTopBanner', g.content)
      }
    })
  }
}

export const getters = {
  coldSignin: (state) => {
    return !state.user && !state.location
  },
  loggedInWithLocation: (state) => {
    return !!state.user && !!state.location
  },
  loggedIn: (state) => {
    return !!state.user
  },
  navmenu: (state) => (slug) => {
    const menu = state.global.find((g) => {
      return g.slug === slug
    })
    return menu ? menu.content.links : []
  },
  categoryInfo: (state) => (id) => {
    try {
      const cat = state.global.find((g) => {
        return g.slug === 'categories'
      })

      const c = cat.content.items.find((c) => {
        return c.items.id === id
      })
      return c || {}
    } catch (err) {
      return {}
    }
  },
  deliveryStatuses: (state) => {
    const s = state.global.find((g) => {
      return g.slug === 'order-statuses'
    })
    const allStats = s ? s.content.delivery_statuses : []

    return allStats
  },

  cartCount: (state) => {
    return state.order && state.order.products && state.order.products.length
      ? state.order.products.reduce((ac, next) => ac + next.quantity, 0)
      : 0
  },

  sortedLocations: (state) => {
    let locs = []
    locs = state.allLocations.map((l) => l)

    const sortedLocs = locs
      ? locs.sort((x, y) => {
          const a = x.city.toUpperCase()
          const b = y.city.toUpperCase()
          return a === b ? 0 : a > b ? 1 : -1
        })
      : []

    return sortedLocs.sort((x, y) => {
      const a = x.state.toUpperCase()
      const b = y.state.toUpperCase()
      return a === b ? 0 : a > b ? 1 : -1
    })
  }
}
