
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      locationRoute: false,
      locationGated: false,
      completeProfile: true
    }
  },
  computed: {
    ...mapState([
      'location',
      'loading',
      'errors',
      'showCart',
      'token',
      'orderConfirming',
      'headerHeight',
      'order',
      'user'
    ]),
    ...mapGetters(['coldSignin']),
    mobile() {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
    onMenu() {
      return (
        this.$route.name === 'order-product' ||
        this.$route.name === 'order-checkout' ||
        this.$route.name === 'order-items' ||
        this.$route.name === 'account-login'
      )
    },
    pageClass() {
      const pageClass = this.$route.path.replace(/^\/|\/$/g, '').replace(/\//g, '-')
      if (pageClass) {
        return 'page-'+pageClass
      } else {
        return 'page-home'
      }
    }
  },
  beforeMount() {
    // Prevents unsightly outline on button click
    document.addEventListener('click', (e) => {
      if (e.target.closest('button')) {
        e.target.closest('button').blur()
      }
      if (e.target.parentElement?.closest('button')) {
        e.target.parentElement.closest('button').blur()
      }
    })
  },
  async mounted() {
    // Added Braze to the client's browser - allowing for testing through the console
    if(this.$braze) window.braze = this.$braze

    if (
      this.token &&
      (!this.token.provider || !this.token.provider.includes('oloauth'))
    ) {
      this.$api.logoutUser()
    }
    if (!process.env.DISABLE_RECAPTCHA) {
      try {
        await this.$recaptcha.init()
      } catch (err) {
        console.error(err)
      }
    }
    if (this.$route.query.store) {
      const order = await this.$api.getLocation(this.$route.query.store)
      if (!order) {
        await this.$api.createOrder()
      }
    }
    if (this.location) {
      await this.$api.getMenu(false)
    }

    // ADA fixes
    const a = document.createElement('a')
    const s = document.body.firstChild
    const linkText = document.createTextNode('Skip to main content')
    a.appendChild(linkText)
    a.title = 'Skip to main content'
    a.href = '#main'
    a.className = 'skiplink'
    s.parentNode.insertBefore(a, s)
    if (this.$route.path === '/locations') {
      this.locationRoute = true
      setTimeout(function() {
        const map = document.querySelectorAll('iframe')[0]
        map.setAttribute('name', 'Google map of Bojangles Locations')
      }, 2000)
    }

    this.locationGated = !this.$store.state.user && !this.$store.state.location

    // Set header and footer heights in vuex on initial load and resize
    this.$nextTick(() => {
      this.setHeights()
      window.addEventListener('resize',this.setHeights)
    })
  },
  destroyed() {
    window.removeEventListener('resize',this.setHeights)
    window.removeEventListener('resize',this.setHeights)
  },
  methods: {
    closeErrors() {
      this.$store.commit('setErrors', [])
    },
    setHeights() {
      const headerHeight = document.querySelector('header').getBoundingClientRect().height+'px'
      const footerHeight = document.querySelector('footer').getBoundingClientRect().height+'px'
      this.$store.commit('setHeaderHeight', headerHeight)
      this.$store.commit('setFooterHeight', footerHeight)
    }
  },
  watch: {
    '$route.path'(e) {
      this.$store.commit('setErrors', [])
      window.scroll({ top: 0, left: 0, behavior: 'smooth' }) // scroll to top on route change
      this.locationRoute = this.$route.path === '/locations'
    }
  }
}
