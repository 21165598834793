import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=bb794726&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=bb794726&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb794726",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMarker: require('/opt/build/repo/components/icons/IconMarker.vue').default,IconChevronDown: require('/opt/build/repo/components/icons/IconChevronDown.vue').default,Footer: require('/opt/build/repo/components/layout/Footer.vue').default})
