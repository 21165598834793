
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['loading', 'errors'])
  },
  data() {
    return {
      height: null
    }
  },
  async mounted() {
    if(!process.env.DISABLE_RECAPTCHA) {
      try {
        await this.$recaptcha.init()
      } catch(err) {
        console.error(err)
      }
    }
    this.$nextTick(function() {
      setTimeout(
        function() {
          this.height = this.mainHeight() // set min height
        }.bind(this),
        100
      )
    })
  }
}
