const braze = require("@braze/web-sdk")

export default (context, inject) => {
  braze.initialize(process.env.BRAZE_SDK_KEY, {
    baseUrl: process.env.BRAZE_SDK_ENDPOINT,
    enableLogging: process.env.BRAZE_LOGGING === "true",
    allowUserSuppliedJavascript: true
  })



  // show all in-app messages without custom handling
  braze.automaticallyShowInAppMessages()

  // When the user logs in or a Refresh is called our callback will sort and return the cards
  braze.subscribeToContentCardsUpdates((updates) => {
    // Arrays for cached or refreshed cards
    let _homepage_carousel_cards = [], _homepage_top_alert_cards = [], _offer_screen_message_card = []

    const cards = updates.cards
    console.log("Cards before Sort", cards)

    cards.forEach(card => {
      if (card.isControl) {
        // Do not display the control card, but remember to call `logContentCardImpressions([card])`
      }
      else if (card.extras["feed_type"] === 'homepage_carousel') {
        _homepage_carousel_cards.push(card)
      }
      else if (card.extras["feed_type"] === 'homepage_top_alert') {
        _homepage_top_alert_cards.push(card)
      }
      else if (card.extras["feed_type"] === 'offer_screen_message') {
        _offer_screen_message_card.push(card)
      }
    })
    context.store.commit('setHomepageCarouselContentCards', [..._homepage_carousel_cards])
    context.store.commit('setHomepageTopAlertContentCards', [..._homepage_top_alert_cards])
    context.store.commit('setOfferScreenContentCards', [..._offer_screen_message_card])
  })

  // Arrays for cached or refreshed cards
  let homepage_carousel_cards = [], homepage_top_alert_cards = [], offer_screen_message_card = []

  // Sort cached cards into arrays for usage by components
  braze.getCachedContentCards().cards.forEach((card) => {
    if (card.isControl) {
      // Do not display the control card, but remember to call `logContentCardImpressions([card])`
    }
    else if (card.extras["feed_type"] === 'homepage_carousel') {
      homepage_carousel_cards.push(card)
    }
    else if (card.extras["feed_type"] === 'homepage_top_alert') {
      homepage_top_alert_cards.push(card)
    }
    else if (card.extras["feed_type"] === 'offer_screen_message') {
      offer_screen_message_card.push(card)
    }
  })

  // Set store cards to sorted card arrays
  context.store.commit('setHomepageCarouselContentCards', [...homepage_carousel_cards])
  context.store.commit('setHomepageTopAlertContentCards', [...homepage_top_alert_cards])
  context.store.commit('setOfferScreenContentCards', [...offer_screen_message_card])

  console.log("Store", context.store)
  // Always run last, after initial configuration is completed
  // Start session for anonymous users
  braze.openSession()

  inject('braze', braze)
  context.$braze = braze
}