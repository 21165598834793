
  import { mapState, mapMutations, mapActions } from 'vuex'
  const axios = require('axios')
  
  export default {
    data() {
      return {
        countAnimation: false,
        loaded: false
      }
    },
    mounted() {
      this.$nextTick(() => {
        const search = document.querySelector('.yxt-Answers-component')
        if (!search && process.client) {
          if (!window.YEXT_SEARCH_INITIALIZED) {
            window.YEXT_SEARCH_INITIALIZED = true
            ANSWERS.init({
              apiKey: '8e126c39edbada8bfd7cf991a1932848',
              experienceKey: 'bojangles-answers',
              businessId: '770291',
              experienceVersion: 'PRODUCTION',
              locale: 'en',
              onReady: function () {
                this.addComponent('SearchBar', {
                  container: '.search',
                  name: 'yext',
                  redirectUrl: '/search',
                  placeholderText: 'Search',
                  areDuplicateNamesAllowed: true
                })
              // Make API Call to Options
                let url = 'https://liveapi-cached.yext.com/v2/accounts/me/answers/autocomplete';
                url += '?v=20190101';
                url += '&api_key=' + "8e126c39edbada8bfd7cf991a1932848";
                url += '&sessionTrackingEnabled=false';
                url += '&experienceKey=' + "bojangles-answers";
                url += '&input=';
                url += '&version=' + "PRODUCTION";
                url += '&locale=' + "en";
                axios.get(url).then(function(response) {
                // Get strings from response
                  const strings = response.data.response.results.map(function(r) {
                    return r.value;
                  })
                // Set up Typed
                  const options = {
                    strings: strings,
                    showCursor: true,
                    cursorChar: "|",
                    typeSpeed: 45,
                    backSpeed: 20,
                    smartBackspace: true,
                    loop: true,
                    startDelay: 500,
                    backDelay: 2000,
                    attr: "placeholder",
                  }
                  const typedElement = document.querySelector(".js-yext-query");
                  if (typedElement) {
                    new Typed(".js-yext-query", options)
                  }
                })
              }
            })
            
            // Update markup of Yext searchbar for ADA
            document.onreadystatechange = () => { 
              if (document.readyState == 'complete') { 
                let clear = document.querySelector('.search .yxt-SearchBar-clear')
                let submit = document.querySelector('.search .yxt-SearchBar-button')
                const form = document.querySelector('.search .yxt-SearchBar-form')
                const input = document.querySelector('.search .yxt-SearchBar-input')
                clear.remove()
                clear = document.createElement('button')
                clear.setAttribute('type','button')
                clear.setAttribute('class','js-yxt-SearchBar-clear yxt-SearchBar-clear')
                clear.setAttribute('data-eventtype','SEARCH_CLEAR_BUTTON')
                clear.setAttribute('data-eventoptions','{}')
                clear.setAttribute('data-is-analytics-attached','true')
                clear.innerHTML = '<svg viewBox="0 1 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M7 8l9.716 9.716m0-9.716L7 17.716" stroke="currentColor" stroke-width="2"></path></svg><span class="yxt-SearchBar-clearButtonText sr-only">Clear</span>'
                submit.remove()
                submit = document.createElement('button')
                submit.setAttribute('class','yxt-SearchBar-button')
                submit.setAttribute('type','submit')
                submit.innerHTML = '<span class="yxt-SearchBar-buttonText sr-only">Submit Search</span>'
                form.appendChild(clear)
                form.appendChild(submit)
                input.addEventListener('keyup', () => {
                  if (input.value.length > 0) {
                    clear.style.display = 'block'
                  } else {
                    clear.style.display = 'none'
                  }
                })
                clear.addEventListener('click', () => {
                  input.value = ''
                  clear.style.display = 'none'
                })
              } 
            }
          }
        }
      })
    },
    computed: {
      ...mapState(['user','showCart','showMobileMenu','location','order','offerScreenContentCards']),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      count() {
        if (this.order && this.order.products.length) {
          let count = 0
          this.order.products.forEach(product => {
            count += product.quantity
          })
          return count
        } else {
          return false
        }
      },
      notifications() {
        return this.offerScreenContentCards.filter((card) => card.viewed === false).length
      }
    },
    methods: {
      toggleMobileMenu() {
        document.activeElement.blur()
        this.$store.commit('setShowMobileMenu', !this.showMobileMenu)
      },
      toggleCart() {
        this.$store.commit('setShowCart', !this.showCart)
      },
      animateCount() {
        this.countAnimation = true
        setTimeout(() => {
          this.countAnimation = false
        },800)
      },
      setPickup() {
        if (this.order && this.order.handoff == 'dispatch') {
          this.$api.setHandoff('pickup')
        } else {
          this.$router.push('/locations?mode=pickup')
        }
      },
      setDelivery() {
        this.$router.push('/locations?mode=delivery')
      }
    },
    watch: {
      'order.products': {
        handler(newProducts, oldProducts) {
          // Animate count if product is added to the order
          if (oldProducts && newProducts) {
            const oldQty = oldProducts.reduce((total, product) => total + product.quantity, 0)
            const newQty = newProducts.reduce((total, product) => total + product.quantity, 0)
            if (newQty > oldQty) {
              this.animateCount()
            }
          }
        },
        deep: true,
      },
    }
  }
