
  import { mapState } from 'vuex'
  
  export default {
    computed: {
      ...mapState(['location','order']),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      showMobileNav() {
        return (
          (this.$mq == 'sm' || this.$mq == 'md') &&
          (
            this.$route.name !== 'order-product' &&
            this.$route.name !== 'order-checkout' &&
            this.$route.name !== 'order-items' &&
            this.$route.name !== 'account-login'
          )
        )
      }
    },
    methods: {
      setPickup() {
        if (this.order && this.order.handoff == 'dispatch') {
          this.$api.setHandoff('pickup')
        } else {
          this.$router.push('/locations?mode=pickup')
        }
      },
      setDelivery() {
        this.$router.push('/locations?mode=delivery')
      }
    },
    mounted() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.showMobileNav) {
            const recaptchaBadge = document.querySelector('.grecaptcha-badge')
            if (recaptchaBadge) {
              recaptchaBadge.style.bottom = '90px'
            }
          } else {
            const recaptchaBadge = document.querySelector('.grecaptcha-badge')
            if (recaptchaBadge) {
              recaptchaBadge.style.bottom = '14px'
            }
          }
        },500)
      })
    },
    watch: {
      showMobileNav(show) {
        if (show) {
          const recaptchaBadge = document.querySelector('.grecaptcha-badge')
          if (recaptchaBadge) {
            recaptchaBadge.style.bottom = '90px'
          }
        } else {
          const recaptchaBadge = document.querySelector('.grecaptcha-badge')
          if (recaptchaBadge) {
            recaptchaBadge.style.bottom = '14px'
          }
        }
      }
    },
  }
